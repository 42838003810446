import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import TextIntro from '@/components/TextIntro'
import { pageFadeInOut } from '@/modules/animationVariants'

const MissionPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Page
      content={
        <PageWrapper>
          <TextIntro
            titleText={t('careers.mission_title')}
            paragraphText={t('careers.mission_body')}
          />
        </PageWrapper>
      }
      settings={{
        backgroundColor: '#5a7d69',
        contentAnimationVariants: pageFadeInOut(),
        useCircularWipe: false,
      }}
    />
  )
}

export default MissionPage
